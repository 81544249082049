.showReservationContainer {
  margin: 20px;
  padding: 24px;
}

.showReservationContainer h3 {
  font-size: 20px;
}

.findInfoReservationText {
  font-size: 14px;
  margin-bottom: 20px;
}

.searchReservationButton {
  background: #0071c2;
  color: #ffffff;
  border: none;
  margin-top: 20px;
}

.clearSearch {
  background: #ffffff;
  color: #0071c2;
  border: none;
  margin-left: 10px;
}

.reservationInputContainer {
  display: flex;
  flex-direction: row;
  gap: 20px;
}

.hotelInputContainer {
  margin-top: 15px;
  display: flex;
  flex-direction: row;
  gap: 20px;
}

.hotelInput {
  width: 15%;
}

.dateInput {
  width: 20%;
}

.dateInputAlignment {
  text-align: right;
}

.emptyContainer {
  margin-bottom: 15px;
  display: flex;
  align-items: center;
  gap: 4px;
}

.emptyContainer img {
  width: 14px;
  height: 14px;
}

.emptyContainer span {
  color: #cc0000;
  font-size: 14px;
}
