.selectControl {
  height: 36px;
  width: 350px;
  margin-top: 8px;
}

.formInput {
  display: flex;
  flex-direction: column;
}

.formInput textarea {
  resize: none;
}

.formInput label {
  font-size: 14px;
}

.invalidInput {
  color: #cc0000;
}

.requestTextArea {
  width: 500px;
}

.requestDetails {
  display: flex;
  flex-direction: column;
  margin-top: 24px;
  width: 500px;
}

.requestDetailsHeader {
  display: flex;
  flex-direction: row;
  margin-bottom: 8px;
}
.requestDetailsHeader label {
  width: 80%;
}
