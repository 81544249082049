.datePickerContainer {
  display: flex;
  flex-direction: column;
  position: relative;
}

.dateField {
  padding: 15px;
  margin: 5px 0;
  border: 1px solid #949494;
  border-radius: 2px;
  box-sizing: border-box;
  height: 40px;
  text-align: center;
}

.datePickerContainer label {
  font-size: 14px;
}

.calendarIcon {
  width: 20px;
  height: 20px;
  position: absolute;
  margin-top: 30px;
  margin-left: 10px;
}
