.detailsContainer {
  display: flex;
  flex-direction: row;
  padding: 3px 0 10px 0;
  font-size: 14px;
}

.detailsLabelContainer {
  width: 55%;
}

.detailsValueContainer {
  width: 91%;
}

.labelText {
  color: #6b6b6b;
}
