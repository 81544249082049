.ulist {
  list-style-type: none;
  padding: 0;
}
.requestContainer {
  margin-top: 1rem;
  margin-bottom: 1rem;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.requestHeader {
  margin-bottom: 0.25rem;
}

.requestContent {
  width: 80%;
}

.requestFooter {
  margin-top: 0.25rem;
  font-size: 12px;
}

.editButton {
  padding: 8px 12px 8px 12px;
  background: #ffffff;
  border: 1px solid #0071c2;
  color: #0071c2;
  cursor: pointer;
  height: fit-content;
  font-size: 14px;
}

.changeRequestsListOuterContainer {
  margin: 20px;
  padding: 24px;
  font-size: 14px;
}

.showMoreButton {
  display: flex;
  gap: 10px;
  background: #ffffff;
  border: 1px solid #0071c2;
  border-radius: 2px;
  color: #0071c2;
}

.componentFooter {
  display: flex;
  width: 100%;
  justify-content: center;
}

.requestBody {
  white-space: pre-wrap;
}

.editButton:disabled {
  background: #d9d9d9;
  color: #949494;
  border: none;
}
