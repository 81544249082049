.buttonContainer {
  border-radius: 2px;
  padding: 12px 12px;
  cursor: pointer;
}

.buttonContainer:disabled {
  background: #d9d9d9;
  color: #949494;
}

.buttonContainer:focus {
  outline: none;
}
