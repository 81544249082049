.reservationPropertyDetailsOuterContainer {
  margin: 20px;
  padding: 24px;
  font-size: 14px;
}

.reservationPropertyDetailsContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.reservationDetails {
  width: 30%;
}

.propertyDetails {
  width: 30%;
}

.roomDetails {
  width: 25%;
}

.statusValueText {
  color: #008009;
}
