.reservationPropertyDetailsOuterContainer {
  margin: 20px;
  padding: 24px;
  font-size: 14px;
}

.columnFlex {
  display: flex;
  flex-direction: column;
}

.rowFlex {
  display: flex;
  flex-direction: row;
}

.changeRequestSaveButton {
  margin-top: 20px;
  margin-left: 15px;
  margin-right: 15px;
  color: white;
  background: #0071c2;
  border: none;
}

.changeRequestCancelButton {
  margin-top: 20px;
  margin-left: 15px;
  margin-right: 15px;
  color: #0071c2;
  background: white;
  border: none;
}

.selectControl {
  height: 36px;
  width: 350px;
  margin-top: 8px;
}

.formInput {
  display: flex;
  flex-direction: column;
  margin-top: 1.5rem;
}

.formInput input {
  padding: 15px;
  margin: 5px 0;
  border-radius: 3px;
  border: 1px solid #949494;
  box-sizing: border-box;
  height: 40px;
  width: 100%;
}
.formInput label {
  font-size: 14px;
}

.invalidInput {
  color: #cc0000;
}

.vectorImg {
  width: 20px;
  height: 20px;
  margin-left: 300px;
  margin-top: 36px;
  position: absolute;
}

select option:first-child {
  color: green;
}
