.appInfoContainer {
  background: #fff0e0;
  padding: 16px;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  gap: 10px;
}

.infoSign {
  width: 24px;
  height: 24px;
}

.alertInfo {
  margin-top: 10px;
  font-size: 14px;
}
