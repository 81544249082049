.reservationTableContainer {
  margin: 20px;
  padding: 24px;
  font-size: 14px;
}

.paginationContainer {
  border: 1px solid #d9d9d9;
  border-top-width: 0;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.paginationBarContainer {
  padding-inline-start: initial;
}

.totalNumberResultsContainer {
  display: flex;
  justify-content: center;
  align-items: center;
}
