.navbarContainer {
  background: #003580;
  padding: 14px 24px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.logoContainer {
  display: flex;
  flex-direction: row;
  gap: 20px;
  align-items: center;
}

.logo {
  height: 24px;
}

.headerText {
  color: #f2f2f2;
}

.connectContainer {
  display: flex;
  flex-direction: row;
  gap: 10px;
  align-items: center;
  justify-content: center;
}

.phoneIcon {
  height: 16px;
  width: 16px;
}
.connectViaPhoneText {
  color: #f2f2f2;
  font-size: 14px;
}

.externalLink {
  text-decoration: none;
}
