.sidebarContainer {
  width: 20%;
  border-radius: 0;
  display: flex;
  flex-direction: column;
  height: 100vh;
  justify-content: space-between;
  align-items: stretch;
  position: sticky;
  top: 0;
}

.changeRequestButton {
  margin-top: 20px;
  margin-left: 15px;
  margin-right: 15px;
  color: white;
  background: #0071c2;
  border: none;
}

.logOutButton {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  margin-bottom: 20px;
  margin-left: 15px;
  margin-right: 15px;
  background: #ffffff;
  border: 1px solid #0071c2;
  border-radius: 2px;
  color: #0071c2;
  width: -moz-available;
  width: -webkit-fill-available;
  width: fill-available;
}
