.landingContainer {
  background: #f2f2f2;
  display: flex;
  width: 100%;
}

.reservationContainer {
  width: 80%;
}

.landingOuterContainer {
  height: 100%;
}
