.paginationContainer {
  display: flex;
  list-style-type: none;
}
.paginationItem {
  padding: 0 12px;
  height: 32px;
  text-align: center;
  margin: auto 3px;
  color: #0071c2;
  display: flex;
  box-sizing: border-box;
  align-items: center;
  letter-spacing: 0.01071em;
  border-radius: 16px;
  line-height: 1.43;
  font-size: 14px;
  min-width: 32px;
}
.paginationItem.dots:hover {
  background-color: transparent;
  cursor: default;
}
.paginationItem:hover {
  background-color: rgba(0, 0, 0, 0.04);
  cursor: pointer;
}
.paginationItem.selected {
  background-color: rgba(0, 0, 0, 0.08);
}
.paginationItem .arrow::before {
  position: relative;
  content: '';
  display: inline-block;
  width: 0.4em;
  height: 0.4em;
  border-right: 0.12em solid #0071c2;
  border-top: 0.12em solid #0071c2;
}
.paginationItem .arrow.left {
  transform: rotate(-135deg) translate(-50%);
}
.paginationItem .arrow.right {
  transform: rotate(45deg);
}
.paginationItem.disabled {
  pointer-events: none;
}
.paginationItem.disabled .arrow::before {
  border-right: 0.12em solid rgba(0, 0, 0, 0.43);
  border-top: 0.12em solid rgba(0, 0, 0, 0.43);
}
.paginationItem.disabled:hover {
  background-color: transparent;
  cursor: default;
}
