.reservationPinContainer {
  font-size: 14px;
  display: flex;
  justify-content: space-between;
}

.reservationIdLabelText {
  font-size: 12px;
  color: #6b6b6b;
  font-weight: 400;
}

.reservationIdText {
  font-size: 24px;
}

.reservationPinBorderBottom {
  margin-top: 20px;
  margin-bottom: 20px;
  border-bottom: 1px solid #d9d9d9;
}

.pinNumberInput {
  width: 51%;
}

.reservationIdInput {
  width: 300px;
}

.reservationPinForm {
  display: flex;
  gap: 20px;
  margin-top: 5px;
}

.checkinPinButton {
  background: #ffffff;
  border: 1px solid #0071c2;
  border-radius: 2px;
  color: #0071c2;
  height: fit-content;
  margin-top: 4px;
  padding: 10px 12px;
}

.errorVectorImg {
  top: 16px;
}

.reservationIdForm {
  display: flex;
  gap: 20px;
}

.pinConfirmedText {
  color: #008009;
}

.checkPinCodeContainer {
  display: flex;
  align-items: center;
  gap: 3px;
  margin-top: 5px;
}

.checkmarkImg {
  width: 18px;
  height: 18px;
}
