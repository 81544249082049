.propertyDetailsContainer {
  margin: 20px;
  padding: 24px;
  font-size: 14px;
}

.propertyDetailsContainer h2 {
  margin-block-start: unset;
  margin-block-end: unset;
  margin-inline-start: unset;
  margin-inline-end: unset;
}

.propertyDetailsText {
  font-size: 20px;
}

.vectorImg {
  width: 14px;
  height: 14px;
}

.propertyDetailsNotification {
  display: flex;
  flex-direction: row;
  gap: 10px;
  align-items: baseline;
  color: #cc0000;
  margin-top: 20px;
  margin-bottom: 20px;
}

.hotelDetailsContainer {
  display: flex;
  flex-direction: row;
  padding: 3px 15px 15px 0;
}

.hotelLabelContainer {
  width: 25%;
}

.labelText {
  color: #6b6b6b;
}
