.sortTableContainer {
  margin-top: 20px;
}
.bookerEmailText {
  font-weight: 400;
}

.childContentText {
  font-size: 12px;
  font-weight: 400;
  color: #6b6b6b;
}

.addressContentText {
  font-size: 14px;
  font-weight: 400;
  color: #6b6b6b;
}

.sortTableHeading {
  display: flex;
  align-items: center;
  gap: 3px;
}

p {
  margin-block-start: unset;
  margin-block-end: unset;
  margin-inline-start: unset;
  margin-inline-end: unset;
}
