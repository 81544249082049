.reservationDetailsOuterContainer {
  height: 100%;
  font-size: 14px;
}

.reservationDetailsContainer {
  background: #f2f2f2;
  display: flex;
  width: 100%;
}

.reservationPropertyDetailsContainer {
  width: 80%;
}

.reservationPropertyDetailsOuterContainer {
  margin: 20px;
  padding: 24px;
  font-size: 14px;
}
